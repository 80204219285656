import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@components/shared/shared.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ServicesModule } from './services/services.module';
import { NgxLoaderIndicatorModule } from 'ngx-loader-indicator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GuardsModule } from './guards/guards.module';
import { environment } from 'environments/environment';
import { BsModalService } from 'ngx-bootstrap/modal';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    // SharedModule,
    // TabsModule,
    ServicesModule,
    NgxLoaderIndicatorModule,
    GuardsModule,
  ],
  providers: [
    {
      provide: 'env',
      useValue: environment,
    },
    BsModalService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
