export class UtilHtml {
  static appendScriptElement(config: {
    src?: string;
    id?: string;
    attr?: string;
    attrVal?: string;
    async?: boolean;
    defer?: boolean;
    callback?: any;
    innerHTML?: string;
    callbackOnlyOnLoad?: boolean;
  }) {
    config.async = config.async ?? false;
    config.defer = config.defer ?? true;
    config.callback = config.callback || (() => {});
    config.callbackOnlyOnLoad = config.callbackOnlyOnLoad ?? true;
    const body = <HTMLDivElement>document.body;

    if (!UtilHtml.checkScriptAlreadyInclude(config.src)) {
      const script = document.createElement('script');
      // script element
      script.innerHTML = config?.innerHTML || '';
      config.attr && script.setAttribute(config.attr, config.attrVal || '');
      config.src && (script.src = config.src);
      config.id && (script.id = config.id);
      script.async = config.async;
      config.defer && (script.defer = config.defer);
      script.onload = config.callback;
      body.appendChild(script);
      return;
    }
    !config.callbackOnlyOnLoad && config.callback();
  }

  static appendNoScriptElement(config: {
    id?: string;
    attr?: string;
    attrVal?: string;
    callback?: any;
    innerHTML?: string;
  }) {
    config.callback = config.callback || (() => {});
    const body = <HTMLDivElement>document.body;

    const noscript = document.createElement('noscript');
    // noscript element
    noscript.innerHTML = config?.innerHTML || '';
    config.attr && noscript.setAttribute(config.attr, config.attrVal || '');
    config.id && (noscript.id = config.id);
    noscript.onload = config.callback;
    body.appendChild(noscript);
  }

  static appendScriptHead(config: {
    src: string;
    id?: string;
    attr?: string;
    attrVal?: string;
    async?: boolean;
    defer?: boolean;
    callback?: any;
    callbackOnlyOnLoad?: boolean;
  }) {
    config.async = config.async || false;
    config.defer = config.defer || true;
    config.callback = config.callback || (() => {});
    config.callbackOnlyOnLoad = config.callbackOnlyOnLoad ?? true;
    const head = <HTMLDivElement>document.head;

    if (!UtilHtml.checkScriptAlreadyInclude(config.src)) {
      const script = document.createElement('script');
      script.innerHTML = '';
      config.attr && script.setAttribute(config.attr, config.attrVal || '');
      script.src = config.src;
      config.id && (script.id = config.id);
      script.async = config.async;
      script.defer = config.defer;
      script.onload = config.callback;
      head.appendChild(script);
      return;
    }
    !config.callbackOnlyOnLoad && config.callback();
  }

  static linkAppendHeadPromise(config: {
    href: string;
    callback?: any;
    callbackOnlyOnLoad?: boolean;
  }) {
    config.callback = config.callback || (() => {});
    config.callbackOnlyOnLoad = config.callbackOnlyOnLoad ?? true;

    return new Promise((resolve, reject) => {
      const head = <HTMLDivElement>document.head;
      if (!UtilHtml.linkCheckExists(config.href)) {
        const link = document.createElement('link');
        // script element
        link.innerHTML = '';
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = config.href;
        link.onload = function () {
          resolve(true);
          config.callback();
        };
        head.appendChild(link);
        return;
      }
      resolve(true);
      !config.callbackOnlyOnLoad && config.callback();
    });
  }

  static appendStyleHead(content = '') {
    // default values
    const head = <HTMLDivElement>document.head;

    const script = document.createElement<'style'>('style');
    // script element
    script.textContent = content;
    head.appendChild(script);
  }

  static checkScriptAlreadyInclude(src: any) {
    const script = document.querySelectorAll(`script[src="${src}"]`);
    return script.length > 0 ? true : false;
  }

  static linkCheckExists(href: any) {
    const script = document.querySelectorAll(`link[href="${href}"]`);
    return script.length > 0 ? true : false;
  }

  static copyToClipboard(text: string): void {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
  }
}
