<app-header></app-header>
<div class="content section-payment">
  <div class="container py-4">
    <div *ngIf="isLoadingPayment" class="loading-spinner-overlay">
      <div class="loading-spinner"></div>
    </div>
    <div class="row gap-5">
      <div class="col-12 col-lg-8">
        <div class="content-title">
          <h1 class="">CONFIRMAR Y PAGAR</h1>
        </div>
        <!-- <div
          class="content-address d-flex justify-content-between align-items-center py-4"
        >
          <div>
            <div class="d-flex">
              <img src="assets/images/svg/icon-a.svg" />
              <p class="mb-0 ms-2 color-7 text">
                Los alamos 7721, San Luis / +01 245 2157
              </p>
            </div>
            <div class="d-flex">
              <img src="assets/images/svg/icon-phone.svg" />
              <p class="mb-0 ms-2 color-7 text">
                Edmundo B. / +511 992 586 695
              </p>
            </div>
          </div>
          <div>
            <button class="btn btn-tertiary">Editar</button>
          </div>
        </div>
        <hr class="my-0" />
        <div class="content-cupons py-3">
          <h6 class="font-bold color-7 ft-18">Cupones</h6>
          <button class="btn btn-tertiary ps-0">+ Añadir cupones</button>
        </div> -->
        <hr class="my-0" />
        <div class="content-detail py-4">
          <h6 class="font-bold color-7 ft-16">Pedido</h6>
          <div
            class="d-flex justify-content-between align-items-center"
            *ngFor="let item of dataPayment.probability"
          >
            <p class="color-8 ft-15 text-uppercase">{{ item.name }}</p>
            <p class="ft-15">{{ item.options }}</p>
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <p class="font-black ft-16">TOTAL</p>
            <p class="font-black ft-16">
              ({{ this.dataPayment.totalAmountInDolars }} USD)
              {{ this.dataPayment.totalAmount }} {{ this.dataPayment.currency }}
            </p>
          </div>
        </div>
        <hr class="my-0" />
        <div class="content-payment py-2">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="font-bold title mt-2">Elige tu método de pago</h4>
            <!-- <button class="btn btn-tertiary">Quiero factura</button> -->
          </div>
          <!-- <div class="mt-4">
            <button class="btn btn-add-card w-100">
              <img
                src="assets/images/svg/icon-add-purpure.svg"
                class="img-fluid me-2"
              />
              Agregar una tarjeta de débito o crédito
            </button>
          </div> -->
          <div class="mt-4">
            <ng-container *ngFor="let payment of myPayments; let i = index">
              <div
                class="d-flex gap-4 align-items-center justify-content-between p-4 payment-item"
              >
                <div class="d-flex gap-3 align-items-center payment-info">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="group"
                    [id]="payment.id"
                    (change)="changeMethod(payment)"
                  />
                  <div class="checkout-payment-icon-wrapper">
                    <img [src]="payment.icon" alt="Payment photo" height="34" />
                  </div>
                  <label
                    [for]="'payment-' + payment.id"
                    class="color-neutral-7 fs-6 payment-label"
                  >
                    {{ payment.name }}
                    <!-- <span *ngIf="payment.lastNumbers !== 0">
                      {{ payment.lastNumbers }}
                    </span> -->
                  </label>
                </div>
                <div></div>
              </div>
            </ng-container>
          </div>

          <div class="content-payment-culqi">
            <input type="hidden" [value]="amountCulqi" id="amountCulqi" />
            <input type="hidden" [value]="currency" id="currency" />
            <input type="hidden" [value]="user?.email" id="email" />
            <input type="hidden" [value]="user?.document" id="document" />
            <input type="hidden" [value]="user?.firstname" id="firstname" />
            <input type="hidden" [value]="user?.lastname" id="lastname" />
            <input type="hidden" [value]="user?.phone" id="phone" />
            <input type="hidden" [value]="country?.abbreviation" id="flag" />
            <input type="hidden" [value]="isYape" id="yape" />
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <span class="color-tertiary font-bold ft-18 mb-3" *ngIf="!userCard"
              >Seleccione un método de pago</span
            >
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <span
              class="color-tertiary font-bold ft-18 mb-3"
              *ngIf="!termsAccepted && userCard"
              >Acepte los términos y condiciones</span
            >
          </div>
          <div
            class="d-flex align-items-center justify-content-center flex-column"
          >
            <div class="my-4">
              <input
                class="form-check-input"
                type="checkbox"
                name="policy"
                [(ngModel)]="termsAccepted"
              />
              <label class="ms-2 color-7"
                >Aceptar
                <a
                  class="text-center my-4 text-decoration-none"
                  routerLink="/lottery/terminos-y-condiciones"
                  target="_blank"
                >
                  términos y condiciones</a
                ></label
              >
            </div>
            <button
              class="btn btn-send"
              *ngIf="methodPay === 'PAYTDC01'"
              [disabled]="!termsAccepted"
              (click)="openCheckout()"
            >
              <span>
                Pagar {{ dataPayment.totalAmount }}
                {{ dataPayment.currency }} ({{
                  dataPayment.totalAmountInDolars
                }}
                USD) con tarjeta</span
              >
            </button>
            <button
              class="btn btn-send"
              *ngIf="methodPay === 'PAYYAPE01'"
              [disabled]="!termsAccepted"
              (click)="openYape()"
            >
              <span>
                Pagar {{ dataPayment.totalAmount }}
                {{ dataPayment.currency }} ({{
                  dataPayment.totalAmountInDolars
                }}
                USD) con Yape</span
              >
            </button>
            <ngx-paypal
              [config]="payPalConfig"
              *ngIf="methodPay === 'PAYPAL01' && termsAccepted"
            ></ngx-paypal>
            <!-- Botón para KeolaPey-->
            <button
              class="btn btn-send"
              *ngIf="methodPay === 'PAYKEOLA01'"
              [disabled]="
                !termsAccepted ||
                isLoadingPayment ||
                this.selectedWallet === null
              "
              (click)="handleKeolaOddsPayment()"
            >
              <span>
                Pagar {{ dataPayment.totalAmount }}
                {{ dataPayment.currency }} ({{
                  dataPayment.totalAmountInDolars
                }}
                USD)</span
              >
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-3 d-none d-lg-block">
        <div>
          <h6 class="font-black ft-16">Resumen</h6>
          <div
            class="card-content d-flex align-items-center justify-content-center flex-column"
          >
            <img
              src="assets/images/svg/icon-money.svg"
              class="img-fluid"
              alt=""
            />
            <span class="font-bold mt-2 text ft-15 text-uppercase"
              >Probabilidades</span
            >
          </div>
          <!-- <div
            class="d-flex justify-content-between align-items-end mt-4"
            *ngFor="let item of dataPayment.probability"
          >
            <p class="color-8 ft-15 mb-0 text-uppercase">{{ item.name }}</p>
            <p class="ft-15 mb-0">{{ item.options }}</p>
          </div> -->
          <div class="d-flex justify-content-between align-items-center mt-2">
            <p class="font-black ft-16">TOTAL</p>
            <p class="font-black ft-16">
              ({{ this.dataPayment.totalAmountInDolars }} USD)
              {{ this.dataPayment.totalAmount }}
              {{ this.dataPayment.currency }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
