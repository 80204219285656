import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UtilHtml } from '@components/utils/html-utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'landing-keola-lotery';
  view: boolean = false;

  constructor(private router: Router, @Inject('env') private env) {}

  ngAfterViewInit(): void {
    // const metaPixelId = this.env.services.meta.initParams.secretKey; //PENDING
    const googlePixelId = this.env.services.google.initParams.secretKey;

    const clarityPixelId = this.env.services.clarity.initParams.secretKey;
    const claritySrc = this.env.services.clarity.scriptSrc;

    // GOOGLE
    googlePixelId &&
      UtilHtml.appendScriptElement({
        id: 'google-script',
        src: this.env.services.google.scriptSrc,
        defer: true,
        async: true,
      });

    googlePixelId &&
      UtilHtml.appendScriptElement({
        id: 'google-init',
        innerHTML: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${googlePixelId}');`,
      });

    // CLARITY
    clarityPixelId &&
      UtilHtml.appendScriptElement({
        id: 'clarity-script',
        innerHTML: `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="${claritySrc}"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "${clarityPixelId}");`,
      });

    //todo META

    // META
    //   metaPixelId &&
    //   UtilHtml.appendScriptElement({
    //     id: 'meta-script',
    //     defer: false,
    //     innerHTML: `!function(f,b,e,v,n,t,s)
    //     {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    //     n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    //     if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    //     n.queue=[];t=b.createElement(e);t.async=!0;
    //     t.src=v;s=b.getElementsByTagName(e)[0];
    //     s.parentNode.insertBefore(t,s)}(window, document,'script',
    //     'https://connect.facebook.net/en_US/fbevents.js');
    //     fbq('init', '${metaPixelId}');
    //     fbq('track', 'PageView');`,
    //   });

    // metaPixelId &&
    //   UtilHtml.appendNoScriptElement({
    //     id: 'meta-noscript',
    //     innerHTML: `<img height="1" width="1" style="display:none"
    //     src="https://www.facebook.com/tr?id=${metaPixelId}&ev=PageView&noscript=1"
    //     />`,
    //   });
  }

  ngOnInit() {
    // Obtener la ruta inicial
    const initialRoute = this.router.url;
    // Verificar la ruta inicial y actualizar la variable view
    this.updateView(initialRoute);

    // Suscribirse al evento NavigationEnd para actualizar la variable view
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = event.url;
        this.updateView(currentRoute);
      }
    });
  }

  updateView(route: string) {
    if (route === '/') {
      this.view = true;
      console.log(this.view);
    } else if (route !== '/') {
      this.view = false;
    } else {
      this.view = true;
    }
  }
}
