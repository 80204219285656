// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  debug: true,
  apiUrlKeola: 'https://i3usmm8f0i.execute-api.us-east-1.amazonaws.com/v1',
  apiNewUrlKeola: 'https://z5a1sdshr6.execute-api.us-east-1.amazonaws.com/v1',
  complaintsBookUrl:
    'https://dev-soyaliadocomercial.keola.club/libro-de-reclamaciones?channel=LOTTERY',
  paypalKey:
    'Ac-SUs9_UC-hGq5-Ne2r_z6nuco1qRWZArDgjXZlCkNGKfV36z0UfexlS7jVHpYtGUzMXbu_GfnFmFdT',
  // culqiPublicKey: 'pk_test_97dfc5225468dfc9', // NUEVAS KEYS DE LOTTERY
  // culqiSecretKey: 'sk_test_fb3d82bccaac4604',
  culqiPublicKey: 'pk_test_bb6e206ad63772be', // KEYS DE K NETWORK
  culqiSecretKey: 'sk_test_0u7P0QpI9P1Lvx1q',
  culqiScriptUrl: 'assets/js/checkout.js',
  apiKeyMap:
    'pk.eyJ1IjoicmFsZXhhbmRlcnZjIiwiYSI6ImNsamVyYzkwYzBrdnIzbG56eDF3aXZ1amEifQ.u_zPsf47GUitN2LaxPw4Rg',
  x_api_key: 'VIe5XRQMEL3hHEPBFbIE74KMz6UMGRH5REa4Zk3c',
  keola_url: 'dev.keolalottery.club/lottery',
  services: {
    google: {
      scriptId: 'google-analytics',
      scriptSrc: 'https://www.googletagmanager.com/gtag/js?id=',
      initParams: {
        secretKey: '',
      },
    },
    clarity: {
      scriptId: 'clarity',
      scriptSrc: 'https://www.clarity.ms/tag/',
      initParams: {
        secretKey: '',
      },
    },
    // meta: {
    //   scriptId: 'meta',
    //   scriptSrc: 'https://connect.facebook.net/en_US/fbevents.js',
    //   initParams: {
    //     secretKey: '',
    //   },
    // },
  },
};
